.error-page {
  display: grid;
  width: 100svw;
  height: 100svh;
  place-items: center;
  place-content: center;
  text-align: center;
  gap: 1.5rem;
  padding: 1rem;
}

.error-page h1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1ch;
  font-weight: 400;
  max-width: 14ch;
}

.line {
  --thickness: 3px;
  --length: 4ch;

  height: var(--thickness);
  width: var(--length);
  background-color: currentColor;
}

.status-code {
  font-weight: 900;
}

.title {
  font-size: 0.625em;
  line-height: 1;
  text-align: left;
}

.error-page p {
  max-width: 40ch;
}

@media screen and (min-width: 48rem) {
  .error-page {
    place-items: flex-start;
    text-align: left;
  }

  .error-page h1 {
    flex-direction: row;
  }

  .line {
    --thickness: 4px;
    --length: 2.5ch;

    width: var(--thickness);
    height: var(--length);
  }
}
